.lay-out__container {
  // background: url('../../../assets/images/backgrounds/bg_slide_0.png');
  background: rgb(229, 67, 65);
  // height: 100vh;
  // width: 1000vw;

  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  min-height: 100vh;
  min-width: 100vw;
}
