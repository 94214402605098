.audio__controls__container {
  width: 100%;
  position: fixed;
  top: 21%;
  left: 94%;
  z-index: 50;
  display: inline-block;
  img {
    width: 4%;
  }
}