* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Cubano';
}
@font-face {
  font-family: 'Cubano';
  src: local('Cubano'), url(./assets/fonts/Cubano.ttf) format('truetype');
}

html {
  font-size: 62.5%;
}

body {
  touch-action: manipulation;
}
