.image__container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;

  background-color: rgb(241, 73, 79);
  .logo__main {
    width: 20%;
  }
}
